define("sculpture/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    items: [//Accretion
    //0
    {
      src: 'assets/images/sculpture/Accretion.jpg',
      w: 4097,
      h: 3072
    }, //Conclusus
    //1
    {
      src: 'assets/images/sculpture/Conclusus.jpg',
      w: 2792,
      h: 4456
    }, //DevelopedSurface
    //2
    {
      src: 'assets/images/sculpture/DevelopedSurface.jpg',
      w: 2848,
      h: 4284
    }, //Equivalent
    //3
    {
      src: 'assets/images/sculpture/Equivalent.jpg',
      w: 4450,
      h: 2967
    }, //MutatisUtrem
    //4
    {
      src: 'assets/images/sculpture/MutatisUtrem.jpg',
      w: 4447,
      h: 2909
    }, //Novem
    //5
    {
      src: 'assets/images/sculpture/Novem.jpg',
      w: 4493,
      h: 2612
    }, //Obliquas
    //6
    {
      src: 'assets/images/sculpture/Obliquas.jpg',
      w: 2365,
      h: 4330
    }, //scgrid1
    //7
    {
      src: 'assets/images/sculpture/scgrid1.jpg',
      w: 3514,
      h: 2682
    }, //scgrid2
    //8
    {
      src: 'assets/images/sculpture/scgrid2.jpg',
      w: 3072,
      h: 3869
    }, //Sedis
    //9
    {
      src: 'assets/images/sculpture/Sedis.jpg',
      w: 4454,
      h: 2590
    }, //sgc6
    //10
    {
      src: 'assets/images/sculpture/sgc6.jpg',
      w: 3993,
      h: 3003
    }, //SplinedForm
    //11
    {
      src: 'assets/images/sculpture/SplinedForm.jpg',
      w: 2958,
      h: 3072
    }, //SquareGrid
    //12
    {
      src: 'assets/images/sculpture/SquareGrid.jpg',
      w: 3072,
      h: 4418
    }, //YellowRelief
    //13
    {
      src: 'assets/images/sculpture/YellowRelief.jpg',
      w: 3504,
      h: 2856
    }, //Blue Twist b
    //14
    {
      src: 'assets/images/sculpture/Blue Twist b.jpg',
      w: 4295,
      h: 3072
    }, //Square Form 1 d
    //15
    {
      src: 'assets/images/sculpture/Square Form 1 d.jpg',
      w: 2880,
      h: 3000
    }, //Square Form 4f
    //16
    {
      src: 'assets/images/sculpture/Square Form 4f.jpg',
      w: 2992,
      h: 4022
    }]
  });

  _exports.default = _default;
});