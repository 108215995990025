define("sculpture/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tl5aYCh2",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"nav\"],[11,\"id\",\"sidebar\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sidebar-header\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"        \"],[7,\"h4\"],[9],[0,\"Stephen Towns\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"list-unstyled components\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"tagName\"],[\"li\"]],{\"statements\":[[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"          Sculpture\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"link-to\",[\"drawings\"],[[\"tagName\"],[\"li\"]],{\"statements\":[[4,\"link-to\",[\"drawings\"],null,{\"statements\":[[0,\"          Drawings\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"link-to\",[\"projects\"],[[\"tagName\"],[\"li\"]],{\"statements\":[[4,\"link-to\",[\"projects\"],null,{\"statements\":[[0,\"          Projects\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"mailto:stephen.towns1@gmail.com\"],[11,\"target\",\"_top\"],[9],[0,\"Contact\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"id\",\"content\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sculpture/application/template.hbs"
    }
  });

  _exports.default = _default;
});