define("sculpture/components/photo-swipe", ["exports", "ember-photoswipe/components/photo-swipe"], function (_exports, _photoSwipe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _photoSwipe.default;
    }
  });
});