define("sculpture/router", ["exports", "sculpture/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('about');
    this.route('sculpture');
    this.route('drawings');
    this.route('projects');
  });
  var _default = Router;
  _exports.default = _default;
});