define("sculpture/drawings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    items: [//5 Linear Elements
    //0
    {
      src: 'assets/images/drawing/5 Linear Elements.jpg',
      w: 2554,
      h: 4296
    }, //Accretus 2
    //1
    {
      src: 'assets/images/drawing/Accretus 2.jpg',
      w: 2304,
      h: 3072
    }, //Attenuated Space
    //2
    {
      src: 'assets/images/drawing/Attenuated Space.jpg',
      w: 1813,
      h: 3559
    }, //Conclusus D 4
    //3
    {
      src: 'assets/images/drawing/Conclusus D 4.jpg',
      w: 2920,
      h: 4431
    }, //Contiguous 3
    //4
    {
      src: 'assets/images/drawing/Contiguous 3.jpg',
      w: 3705,
      h: 2656
    }, //Ischium 1
    //5
    {
      src: 'assets/images/drawing/Ischium 1.jpg',
      w: 3230,
      h: 2173
    }, //leaf study 1
    //6
    {
      src: 'assets/images/drawing/leaf study 1.jpg',
      w: 2360,
      h: 4048
    }, //Perpello 1
    //7
    {
      src: 'assets/images/drawing/Perpello 1.jpg',
      w: 2418,
      h: 3438
    }, //Premo 1
    //8
    {
      src: 'assets/images/drawing/Premo 1.jpg',
      w: 1986,
      h: 2976
    }, //TJD 3
    //9
    {
      src: 'assets/images/drawing/TJD 3.jpg',
      w: 2947,
      h: 3931
    }, //TJD 6
    //10
    {
      src: 'assets/images/drawing/TJD 6.jpg',
      w: 2690,
      h: 4195
    }, //Blue Space 4
    //11
    {
      src: 'assets/images/drawing/Blue Space 4.jpg',
      w: 3770,
      h: 2727
    }, //Bounded Space3
    //12
    {
      src: 'assets/images/drawing/Bounded Space3.jpg',
      w: 3896,
      h: 2715
    }, //Processus D 15
    //13
    {
      src: 'assets/images/drawing/Processus D 15.jpg',
      w: 3971,
      h: 2891
    }]
  });

  _exports.default = _default;
});