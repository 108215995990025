define("sculpture/projects/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    items: [//VictoryParkSignB
    //0
    {
      src: 'assets/images/projects/VictoryParkSignB.jpg',
      w: 4608,
      h: 3072
    }, //RWA1
    //1
    {
      src: 'assets/images/projects/RWA1.jpg',
      w: 2880,
      h: 1920
    }, //Equivalent
    //2
    {
      src: 'assets/images/projects/RWA2.jpg',
      w: 2880,
      h: 1920
    }, //Catherda Superficiem 1 B s
    //3
    {
      src: 'assets/images/projects/Catherda Superficiem 1 B s.jpg',
      w: 3072,
      h: 4608
    }, //Continebat
    //4
    {
      src: 'assets/images/projects/Continebat.jpg',
      w: 2495,
      h: 4513
    }]
  });

  _exports.default = _default;
});