define("sculpture/about/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "456T6itY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"About\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"line\"],[9],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"Stephen explores biomorphic form using concepts from biology and topology; in particular the structural processes inherent in growth.\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"Many of the forms arise from the utilisation of linear elements within a grid that undergoes simple mathematical transformations, using chance.\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"Later work involves the appropriation of specific designed forms/containers which are transformed topologically through a variety of processes, eg invagination, distortion, attenuation etc to create new forms.\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"The use of material & colour is inherent in the structural and physical properties of the process resulting in forms that are luxuriously tactile.\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"These forms are not mimetic but are associative.  The iterative use of elements in the making process imparts a ritualistic quality to the objects.\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sculpture/about/template.hbs"
    }
  });

  _exports.default = _default;
});